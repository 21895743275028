import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Ayetu } from '@ayetu/sdk-js';

@customElement('contacts-get')
export class ContactsGet extends LitElement {
  static styles = css`
    .get-contacts {
      width: 100%;
      margin: 4px 2px;
      position: relative;
    }
    .get-button {
      display: block;
      width: 100%;
      height: 34px;
      border-radius: 5px;
      background-color: #008cba;
      color: white;
      border: none;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
    }
    .get-button:hover {
      background-color: #007bb5;
    }
    .contact-list {
      margin-top: 10px;
      padding: 0;
      list-style-type: none;
    }
    .contact-list li {
      background-color: #f9f9f9;
      margin-bottom: 5px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  `;

  @property({ type: Array })
  contacts: Array<{ id: string; name: string }> = [];

  @property({ type: Boolean })
  debugMode: boolean = false;

  async handleGetContacts() {
    if (this.debugMode) {
      console.log('Getting contacts...');
    }

    try {
      const result = await Ayetu.contact.get();
      if (result.success) {
        console.log('Contacts retrieved successfully', this.contacts);
      } else {
        console.error('Failed to retrieve contacts:', result.error);
      }
    } catch (error) {
      console.error('Error retrieving contacts:', error);
    }
  }

  render() {
    return html`
      <div class="get-contacts">
        <button class="get-button" @click=${this.handleGetContacts}>Get Contacts</button>
        <ul class="contact-list">
          ${this.contacts.map(contact => html`<li>${contact.name} (ID: ${contact.id})</li>`)}
        </ul>
      </div>
    `;
  }
}
